import React from 'react'
import Header from '../components/Header'
import CALCULATOR from '../assets/icons/calculator_violet.png';

export default function Calculator() {
  return (
    <div>
      <Header
        logo={CALCULATOR}
        title='Parameters Calculator'
        text='Check and run the tools used to calculate Kleros Court Parameters.'
      />
      
      Soon ....
    </div>
  )
}
